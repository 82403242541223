const colors = {
  PRIMARY: "#02517C",
  PRIMARY_DARK: "#02527C",
  WHITE: "#ffffff",
  SNOW: "#FCF7F8",
  BLACK: "#1A202C",
  PRIMARY_DARK_TEXT: "#1A202C",
  STRONG_TEXT: "#2D3748",
  MEDIUM_TEXT: "#4A5568",
  SUBTLE_TEXT: "#718096",
  BOX_TEXT: "#3182CE",
  LIGHT_GREY_BACKGROUND: "#F7FAFC",
  ERROR: "red",
  ORANGE: "#E1620D",
  DARK_BLUE_BACKGROUND: "#003559",
  DROPDOWN_HOVER_BACKGROUND: "#eff2f5",
  FOOTER_LINK: "#1A202C",
  FOOTER_LINK_HOVER: "#2d3748",
  FORM_BORDER: "#a4a8ad",
  CUSTOMER_STATEMENTS_BG: "rgba(244,244,245,1)",
};

export default colors;

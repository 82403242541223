import { Link } from "react-router-dom";
import { useRef, useState, useContext } from "react";
import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CookieContext } from "../../App.js";
import Cookies from "js-cookie";
import { FiPlus } from "react-icons/fi";
import colors from "../../constants/colors";
import { styled } from "styled-components";

function CookieConsent({ forceUpdate }) {
  const { pathname } = useLocation();
  const overlay = useRef();
  const [showCookie, showCookieModal] = useState(false);
  const { cookieConsent, setCookieConsent } = useContext(CookieContext);
  const [showCookieSettings, setCookieSettings] = useState(false);

  const [nescessaryOpen, setNescessaryOpen] = useState(false);
  const [functionalOpen, setFunctionalOpen] = useState(false);
  const [marketingOpen, setMarketingOpen] = useState(false);

  const [functionalCookies, setFunctionalCookies] = useState(
    Cookies.get("FunctionalCookieConsent") === "true" ? "true" : "false"
  );
  const [marketingCookies, setMarketingCookies] = useState(
    Cookies.get("MarketingCookieConsent") === "true" ? "true" : "false"
  );
  const [nescessaryCookies, setNescessaryCookies] = useState(
    Cookies.get("NescessaryCookieConsent") === "true" ? "true" : "false"
  );

  useEffect(() => {
    setFunctionalCookies(
      Cookies.get("FunctionalCookieConsent") === "true" ? "true" : "false"
    );
    setMarketingCookies(
      Cookies.get("MarketingCookieConsent") === "true" ? "true" : "false"
    );
    setNescessaryCookies(
      Cookies.get("NescessaryCookieConsent") === "true" ? "true" : "false"
    );
  }, []);

  useEffect(() => {
    if (Cookies.get("CookieModalShown") !== "true") {
      if (pathname !== "/om-cookies") {
        overlay.current.style.display = "block";
        showCookieModal(true);
      } else {
        overlay.current.style.display = "none";
        showCookieModal(false);
      }
    }
  }, [showCookieModal, pathname]);

  const handleAcceptClick = async (e) => {
    e.preventDefault();
    setCookieSettings(false);

    setCookieConsent({
      functionalCookies: "true",
      marketingCookies: "true",
      nescessaryCookies: "true",
    });

    Cookies.set("FunctionalCookieConsent", "true");
    Cookies.set("MarketingCookieConsent", "true");
    Cookies.set("NescessaryCookieConsent", "true");

    Cookies.set("CookieModalShown", "true");
    overlay.current.style.display = "none";
    showCookieModal(false);
  };

  const handleSettingClick = () => {
    setCookieSettings(true);
  };

  const handleSaveClick = () => {
    setCookieSettings(false);
    setCookieConsent({
      functionalCookies: functionalCookies,
      marketingCookies: marketingCookies,
      nescessaryCookies: "true",
    });

    Cookies.set("FunctionalCookieConsent", functionalCookies);
    Cookies.set("MarketingCookieConsent", marketingCookies);
    Cookies.set("NescessaryCookieConsent", "true");
    Cookies.set("CookieModalShown", "true");

    overlay.current.style.display = "none";
    showCookieModal(false);
  };

  return (
    <>
      <Overlay ref={overlay}></Overlay>
      <CookieFormWrapper
        style={showCookie === false ? { display: "none" } : {}}
        id="CookieSetting"
      >
        {showCookieSettings === false ? (
          <>
            <Heading>Du har kontroll över dina uppgifter</Heading>
            <Description>
              Vi använder cookies för att hemsidan och våra tjänster ska fungera
              som de ska. Cookies hjälper oss att förbättra användarvänligheten
              för dig som besökare. <br />
              <Link
                style={{ color: colors.BLACK, textUnderlineOffset: "2px" }}
                to="/om-cookies"
              >
                Läs mer om cookies
              </Link>
            </Description>

            <AcceptButton
              color={colors.BLACK}
              onClick={handleAcceptClick}
              className="accept"
            >
              Godkänn
            </AcceptButton>

            <div>
              <ShowDetailsOrSaveButton onClick={handleSettingClick}>
                Visa detaljer
              </ShowDetailsOrSaveButton>
            </div>
          </>
        ) : (
          <>
            {/* <div className="settings"> */}
            <Heading>Cookieinställningar</Heading>
            <Settings>
              <Choice>
                <ReadDetailsButton
                  className={`${nescessaryOpen}`}
                  onClick={() => {
                    if (!nescessaryOpen) {
                      setFunctionalOpen(false);
                      setMarketingOpen(false);
                    }
                    setNescessaryOpen(!nescessaryOpen);
                  }}
                >
                  <FiPlus />
                  <h5>Nödvändiga</h5>
                </ReadDetailsButton>
                <p
                  style={{
                    display: nescessaryOpen === true ? "block" : "none",
                  }}
                >
                  Nödvändiga cookies är de som behövs för att hemsidan skall
                  fungera korrekt. Vi samlar även in viss information genom
                  cookies i analytiskt syfte för att förbättra vår hemsida och
                  ge oss information om effekten av vår marknadsföring. Vi
                  samlar aldrig in någon personlig information via cookies.
                </p>

                <Switch>
                  <input type="checkbox" />
                  <Slider style={{ opacity: 0.65 }} className="checked">
                    <InnerSlider className="checked"></InnerSlider>
                  </Slider>
                </Switch>
              </Choice>
              <Choice>
                <ReadDetailsButton
                  className={`${functionalOpen}`}
                  onClick={() => {
                    if (!functionalOpen) {
                      setNescessaryOpen(false);
                      setMarketingOpen(false);
                    }
                    setFunctionalOpen(!functionalOpen);
                  }}
                >
                  <FiPlus />
                  <h5>Funktionella</h5>
                </ReadDetailsButton>
                <p
                  style={{
                    display: functionalOpen === true ? "block" : "none",
                  }}
                >
                  Funktionella cookies används för att tillhandahålla vissa
                  specifika funktioner på en hemsida. Om du accepterar
                  funktionella cookies använder vi det för att tillhandahålla
                  mötesbokningssystem samt chattfunktion som kräver cookies för
                  att fungera. Accepterar du inte funktionella cookies kommer du
                  inte kunna använda mötesbokningssystemet eller chatten.
                </p>
                <Switch>
                  <input
                    onClick={() =>
                      setFunctionalCookies(
                        functionalCookies === "true" ? "false" : "true"
                      )
                    }
                    type="checkbox"
                  />
                  <Slider
                    className={functionalCookies === "true" ? "checked" : ""}
                  >
                    <InnerSlider
                      className={functionalCookies === "true" ? "checked" : ""}
                    ></InnerSlider>
                  </Slider>
                </Switch>
              </Choice>
              <Choice>
                <ReadDetailsButton
                  className={`${marketingOpen}`}
                  onClick={() => {
                    if (!marketingOpen) {
                      setNescessaryOpen(false);
                      setFunctionalOpen(false);
                    }
                    setMarketingOpen(!marketingOpen);
                  }}
                >
                  <FiPlus />
                  <h5>Marknadsföring</h5>
                </ReadDetailsButton>
                <p
                  style={{
                    display: marketingOpen === true ? "block" : "none",
                  }}
                >
                  Marknadsföringscookies har som funktion att kartlägga hur
                  besökare rör sig mellan olika webbplatser. Syftet med dessa är
                  kunna erbjuda besökaren personanpassade och relevanta annonser
                  på de webbsidor som besökare senare besöker. Till exempel kan
                  marknadsföringscookies användas för att visa annonser för dem
                  som har besökt ens webbplats.
                </p>
                <Switch>
                  <input
                    type="checkbox"
                    onClick={() =>
                      setMarketingCookies(
                        marketingCookies === "true" ? "false" : "true"
                      )
                    }
                  />
                  <Slider
                    className={marketingCookies === "true" ? "checked" : ""}
                  >
                    <InnerSlider
                      className={marketingCookies === "true" ? "checked" : ""}
                    ></InnerSlider>
                  </Slider>
                </Switch>
              </Choice>
            </Settings>

            <div style={{ marginBottom: "10px" }}>
              <ShowDetailsOrSaveButton
                type="submit"
                onClick={handleSaveClick}
                className="save"
              >
                Spara val
              </ShowDetailsOrSaveButton>
            </div>
            <AcceptButton onClick={handleAcceptClick} className="accept">
              Godkänn alla
            </AcceptButton>
          </>
        )}
      </CookieFormWrapper>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1a202c;
  opacity: 0.5;
  z-index: 6;
  display: none;
`;

const CookieFormWrapper = styled.div`
  width: 98%;
  max-width: 500px;
  min-height: 350px;
  padding: 0px 20px 20px 20px;
  z-index: 1500;
  border-radius: 10px;
  background-attachment: fixed;
  animation: none;
  filter: none;
  position: fixed;
  right: 1%;
  bottom: 1vw;
  background-color: ${colors.WHITE};
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;

  @media (max-width: 600px) {
    width: 86%;
    right: 2%;
    left: 2%;
    bottom: 1%;
  }

  @media (max-width: 350px) {
    width: 90%;
    padding: 0px 4% 10px 4%;
    right: 1%;
    left: 1%;
    bottom: 1%;
  }
`;

const Heading = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 20px;
  font-size: 20px;

  @media (max-width: 961px) {
    font-size: 22px;
  }
`;

const Description = styled.div`
  font-size: 17px;
  text-align: left;
  margin-left: 5%;
`;

const AcceptButton = styled.button`
  background-color: transparent;
  color: ${colors.BLACK};
  border: none;
  cursor: pointer;
  width: 75%;
  padding: 12px 22px;
  margin: 15px auto 5px auto;
  font-size: 16px;
  font-weight: 600;
  display: block;
  text-decoration: none;
  position: relative;

  &::after {
    content: "";
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border: 2px solid ${colors.BLACK};
    border-radius: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  @media (min-width: 961px) {
    &::after {
      transition: width 0.2s ease 0s, height 0.2s ease 0.1s,
        border-top-width 0.1s ease 0.2s, margin 0.2s ease 0s;
    }
    &:hover:after {
      width: 60%;
      height: 0;
      border-width: 0 2px 2px;
      margin: 0 20%;
      transition: width 0.2s ease 0.2s, height 0.2s ease 0.1s,
        border-top-width 0.1s ease 0s, margin 0.2s ease 0.2s;
    }
  }
`;

const ShowDetailsOrSaveButton = styled.button`
  font-weight: 600;
  all: unset;
  text-decoration: none;
  color: ${colors.BLACK};
  border-bottom: 1px solid ${colors.BLACK};
  font-size: 16px;

  @media (min-width: 961px) {
    &:hover {
      border-bottom: 1px solid ${colors.PRIMARY};
      cursor: pointer;
      color: ${colors.PRIMARY};
    }
  }
`;

const Settings = styled.div`
  width: 90%;
  margin: 30px auto 0 auto;
  text-align: left;

  @media (max-width: 550px) {
    width: 100%;
  }
`;

const Choice = styled.div`
  border-radius: 3px;
  box-shadow: 0 10px 30px 0 rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-bottom: 25px;
  margin-right: 0;
  padding: 11px 10px;
  text-align: left;

  p {
    padding: 2px;
    font-size: 14px;
    margin-top: 8px;
    text-align: left;
  }
`;

const ReadDetailsButton = styled.button`
  width: 100%;
  height: 34px;
  background-color: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  border: none;

  svg {
    color: #1a202c;
    cursor: pointer;
    font-size: 23px;
  }

  h5 {
    margin: 0 0 0 20px;
    font-size: 15px;
  }

  &.true svg {
    transform: rotate(45deg);
  }
`;

const Switch = styled.label`
  position: absolute;
  margin-left: 68%;
  width: 56px;
  height: 30px;
  margin-top: 1px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  @media (max-width: 550px) {
    margin-left: 73%;
  }

  @media (max-width: 465px) {
    margin-left: 68%;
  }

  @media (max-width: 350px) {
    margin-left: 65%;
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &.checked {
    background-color: ${colors.BLACK};
  }
`;

const InnerSlider = styled.span`
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;

  &.checked {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
  }
`;

export default CookieConsent;

import React from "react";
import ReactGA from "react-ga4";
import Cookies from "js-cookie";

function withRouter() {
  const RouteChangeTracker = ({ history }) => {
    history.listen((location, action) => {
      if (Cookies.get("PerformanceCookieConsent") === "true") {
        ReactGA.set({ page: location.pathname });
        ReactGA.send({ hitType: "pageview", page: location.pathname });
      }
    });

    return <></>;
  };

  return RouteChangeTracker;
}

export default withRouter;
